<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="800px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="5" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Upload documents
          </div>
        </v-col>
      </v-row>
      <v-form class="pb-20" ref="form" v-model="validForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10" cols="12" sm="5">
            <span class="font-normal nameField--text text-sm-right">Documents</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="vendorName"
            ></portal-target>
          </v-col>

          <v-col class="pb-8 pb-sm-10" cols="12" sm="7">
            <UploadFiles :files.sync="files"/>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>

        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Upload</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import UploadFiles from '@/components/drag-and-drop/UploadFiles'

import { uploadVerificationFiles } from '@/api/payment-routes'

export default {
  name: 'DialogAddDocuments',
  inheritAttrs: false,
  components: { CancelButton, ActionButton, UploadFiles },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    method: {
      type: String | Number,
      default: ''
    },
  },
  data: () => ({
    loading: false,
    validForm: true,
    files: [],
    rules: []
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        this.loading = true

        if (!this.files && !this.files.length) {
          return
        }

        let formData = {}
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }

        formData = new FormData()

        this.files.forEach(file => {
          formData.append('files[]', file, file.name)
        })

        const { success, payload, message } = await uploadVerificationFiles({
          method: this.method,
          formData,
          config,
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.closeDialog()
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show(value) {
      if (!value) {
        this.rules = []
        this.$refs.form.reset()
        this.load = false
      }
    }
  }
}
</script>