<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Manage Cards</div>
      </portal>
  
      <ActionOutlinedButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :to="{ name: 'FillBalance'}">
        Fill Balance
      </ActionOutlinedButton>
      
      <ActionOutlinedButton class="mr-5 mr-md-11 mb-4 mb-sm-15" @click="openDialogAddCard">
        Add Card
      </ActionOutlinedButton>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
    </div>
    
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No saved cards</span>
          </div>
        </template>
  
        <template #item.status="{ item }">
          <span :class="item.status ? 'success--text' : 'error--text'">{{ item.status ? 'Yes' : 'No' }}</span>
        </template>

        <template #item.default="{ item }">
          <SwitchSm
            :value="item.default"
            @input="changeDefault(item)"
            :loading="loadingStatus"
            bgColor="switcherViolet"
            :disabled="item.default"
          />
        </template>
  
        <template #item.actions="{ item }">
          <TooltipAction bottom message="Add verification documents">
            <template #activator>
              <v-icon class="mr-6" @click="openAddDocumentsDialog(item)">$addDocumentsIcon</v-icon>
            </template>
          </TooltipAction>

          <TooltipAction bottom message="Delete">
            <template #activator>
              <v-icon @click="openDeleteMethodDialog(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
      </v-data-table>

      <div class="pb-26"></div>
    </div>
  
    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      @actionSuccess="actionSuccess"
    />
    
    <DialogAddPaymentMethod
      v-model="dialogAddPaymentMethod"
      @addSuccess="addSuccess"
    />

    <DialogAddDocuments
      v-model="dialogUploadDocuments"
      :method="currentMethod"
    />
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SwitchSm from '@/components/controls/SwitchSm'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogAddPaymentMethod from '@/components/dialog/DialogAddPaymentMethod'
import DialogAddDocuments from '@/components/dialog/DialogAddDocuments'
import TooltipAction from '@/components/tooltip/TooltipAction'

import { getDefaultMethod, getBillingMethods, setDefaultMethod, deletePaymentMethod } from '@/api/payment-routes'

export default {
  name: 'ManageCards',
  components: { ActionOutlinedButton, RefreshButton, SwitchSm, DialogConfirm, DialogAddPaymentMethod, DialogAddDocuments, TooltipAction },
  data: () => ({
    loading: false,
    loadingAction: false,
    loadingStatus: false,
    confirmDialog: false,
    dialogAddPaymentMethod: false,
    dialogUploadDocuments: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    selectedMethod: null,
    defaultMethod: null,
    currentMethod: null,
    dialogIsDelete: false,
    items: [
      // {
      //   name: '**** **** **** 3220',
      //   id: 'pm_1KZaavJmPUxegtt4LaV6cHF7',
      //   country: 'IE',
      //   type: 'visa',
      //   expireDate: '2/2033',
      //   status: false,
      //   default: false,
      // }
    ],
    headers: [
      { text: 'Type', value: 'type', sortable: false, width: 200 },
      { text: 'Card Number', value: 'name', sortable: false },
      { text: 'Expiration', value: 'expireDate', sortable: false },
      { text: 'Country', value: 'country', sortable: false },
      { text: 'Verified', value: 'status', sortable: false, align: 'center' },
      { text: 'Default', value: 'default', sortable: false, align: 'center', width: 140 },
      { text: 'Actions', value: 'actions', width: 40, sortable: false, align: 'end' }
    ],
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh() {
      this.loading = true
      this.items = []
      await this.loadDefaultMethods()
      this.loadPaymentMethods()
    },
    async loadDefaultMethods() {
      const { success, payload, message } = await getDefaultMethod()

      if (success) {
        this.defaultMethod = payload.id
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async loadPaymentMethods() {
      const { success, payload, message } = await getBillingMethods()

      if (success) {
        this.parseCards(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    parseCards(cards = []) {
      if (!cards.length) {
        return
      }

      this.items = cards.map(({ id, card, metadata }) => this.getCardInfo(id, card, metadata))
    },
    getCardInfo(id, card, metadata) {
      return {
        name: `**** **** **** ${card.last4}`,
        id: id,
        country: card.country,
        type: card.brand,
        expireDate: `${card['exp_month']}/${card['exp_year']}`,
        default: this.defaultMethod === id,
        status: Array.isArray(metadata) || metadata.validated === 'true',
      }
    },
    openDialogAddCard() {
      this.dialogAddPaymentMethod = true
    },
    async addSuccess(method) {
      if (!method) {
        return
      }
      
      await this.loadDefaultMethods()
      
      this.items.unshift(this.getCardInfo(method.id, method.card, method.metadata))
    },
    changeDefault(item) {
      this.dialogIsDelete = false
      this.dialogTitle = 'Change default card'
      this.dialogMessage = 'Are you sure you want to change default card?'
      this.dialogSubTitle = item.name
      this.selectedMethod = item
      this.confirmDialog = true
    },
    async actionSuccess() {
      if (this.dialogIsDelete) {
        this.deletePaymentCard()
      } else {
        this.setDefaultCard()
      }
      
    },
    async setDefaultCard() {
      this.loadingAction = true

      const { success, payload, message } = await setDefaultMethod({
        'payment_method': this.selectedMethod.id
      })

      if (success) {
        this.items = this.items.map(item => ({
          ...item,
          default: item.id === this.selectedMethod.id
        }))

        this.confirmDialog = false
        this.$notify({ type: 'success-bg', text: message })
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    async deletePaymentCard() {
      this.loadingAction = true

      const { success, payload, message } = await deletePaymentMethod({
        'payment_method': this.selectedMethod.id
      })

      if (success) {
        this.refresh()
        this.confirmDialog = false
        this.$notify({ type: 'success-bg', text: message })
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    openAddDocumentsDialog(method) {
      this.currentMethod = method.id
      this.dialogUploadDocuments = true
    },
    openDeleteMethodDialog(method) {
      this.dialogIsDelete = true
      this.dialogTitle = 'Delete payment card'
      this.dialogMessage = 'Are you sure you want to delete this card?'
      this.dialogSubTitle = method.name
      this.selectedMethod = method
      this.confirmDialog = true
    },
  },
}
</script>
