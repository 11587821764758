<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="550px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="4" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Add card
          </div>
        </v-col>
      </v-row>
      <v-form class="pb-10" ref="form" v-model="validForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-0" cols="12" sm="4">
            <span class="font-normal nameField--text text-sm-right">Card</span>
          </v-col>
          
          <v-col cols="12" sm="8">
            <input type="hidden" name="payment_method" class="payment-method">
            <div class="credit-card-field p-relative">
              <div id="card-element"></div>

              <v-progress-linear
                :active="stripeLoading"
                :indeterminate="stripeLoading"
                absolute
                bottom
                height="2"
                color="primary"
              ></v-progress-linear>
            </div>
          </v-col>

          <v-col cols="12" sm="8" offset-sm="4">
            <div class="textLink--text font-normal mt-6">
              <span class="loginLinkColor--text pr-2">You must</span>
              <TextLink classes="font-normal" textColor="primary--text" textColorHover="linkColorHover--text" @click="goToSupport">verify your card</TextLink>
            </div>
          </v-col>

        </v-row>
      </v-form>
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>
        
        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Save</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import TextLink from '@/components/links/TextLink'

import { getBillingPrepare, savePaymentMethod } from '@/api/payment-routes'

import { loadStripe } from '@stripe/stripe-js/pure'

export default {
  name: 'DialogAddPaymentMethod',
  inheritAttrs: false,
  components: { CancelButton, ActionButton, TextLink },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    loading: false,
    stripeLoading: true,
    validForm: true,
    pulishableKey: null,
    token: null,
    card: null,
    stripeElement: null,
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    stripe() {
      return loadStripe(this.pulishableKey, { locale: 'en' })
    },
    themeIsDark() {
      return this.$vuetify.theme.isDark
    },
    stripeStyle() {
      return {
        base: {
          color: this.themeIsDark ? '#dadada' : '#6c6c6c',
          fontFamily: '"Fira Sans", sans-serif',
          fontSize: '14px',
          '::placeholder': {
            color: '#A0A0A0'
          }
        },
        invalid: {
          color: '#F06269',
          iconColor: '#F06269'
        },
      }
    }
  },
  methods: {
    async loadBillingPrepare() {
      const { success, payload, message } = await getBillingPrepare()

      if (success) {
        this.token = payload.intent['client_secret']
        this.pulishableKey = `${payload.key}`
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async createStripeElement() {
      this.stripeElement = await this.stripe
      this.stripeLoading = false
      const elements = this.stripeElement.elements({
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500;700&family=Poppins:wght@600&display=swap',
        }],
      })

      this.card = elements.create('card', { style: this.stripeStyle, hidePostalCode: true })
      this.card.mount('#card-element')
    },
    async submitForm() {
      this.loading = true

      try {
        const data = await this.stripeElement.confirmCardSetup(
          this.token,
          {
            payment_method: {
              card: this.card,
            }
          }
        )

        if (data?.error) {
          this.$notify({ type: 'error-bg', duration: 15000, text: data.error.message })
          this.loading = false
        } else {
          this.setBillingProcess({
            'payment_method': data.setupIntent['payment_method']
          })
        }
      } catch (e) {
        this.$notify({ type: 'error-bg', duration: 15000, text: e.error.message })
        this.loading = false
      }
    },
    async setBillingProcess(formData) {
      const { success, payload, message } = await savePaymentMethod(formData)

      if (success) {
        this.$emit('addSuccess', payload)
        this.$notify({ type: 'success-bg', text: message })
        this.closeDialog()
      } else {
        this.loading = false
        this.loadBillingPrepare()
        message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    closeDialog() {
      this.show = false
    },
    destroyCard() {
      this.pulishableKey = null
      if (this.card?.destroy) {
        this.card.destroy()
      }
    },
    goToSupport() {
      window.open('https://dialics.com/support/getting-started/what-is-card-verification/', '_blank')
    },
  },
  watch: {
    pulishableKey: {
      handler(key) {
        if (key) {
          this.createStripeElement()
        }
      }
    },
    themeIsDark: {
      handler() {
        this.destroyCard()
        this.createStripeElement()
      }
    },
    show: {
      handler(show) {
        if (show) {
          this.loadBillingPrepare()
        } else {
          this.loading = false
          this.destroyCard()
        }
      }
    },
  },
}
</script>
